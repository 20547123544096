import React from "react"
import { RSVPCombinedResponse } from "../../../functions/api/rsvp/types"
import { MealChoiceLabelMap } from "../../components/GuestForm/GuestForm"
import Heading from "../../components/Heading"
import Paragraph from "../../components/Paragraph"
import { formatDate } from "../../util/formatDate"

export const ReportScreen = ({
  responses,
}: {
  responses: RSVPCombinedResponse[]
}) => {
  return (
    <>
      <Heading level="1">RSVP Report</Heading>
      {responses.map((response, i) => (
        <div
          style={
            i !== responses.length - 1
              ? {
                  marginBottom: "var(--s2)",
                  borderBottom: "1px solid rgba(0, 0, 0, .2)",
                  paddingBottom: "var(--s2)",
                }
              : {}
          }
        >
          {response.rsvps.map((rsvp) => {
            return (
              <div style={{ marginBottom: "var(--s2)" }}>
                <Heading level="2" title={rsvp.id}>
                  {rsvp.firstName} {rsvp.lastName}{" "}
                  {rsvp.responder && (
                    <small>{`« ${formatDate(new Date(response.date), {
                      hour: "numeric",
                      minute: "numeric",
                    })} »`}</small>
                  )}
                </Heading>
                <Paragraph>
                  {rsvp.attending ? "✅ Attending" : "🚫 Not Attending"}
                </Paragraph>
                {rsvp.attending && rsvp.mealChoice && (
                  <Paragraph style={{ marginBottom: "var(--s1)" }}>
                    Meal choice:{" "}
                    <em>{MealChoiceLabelMap[rsvp.mealChoice ?? ""]}</em>
                  </Paragraph>
                )}
                {rsvp.attending && rsvp.allergies && (
                  <Paragraph>
                    Allergies: <em>{rsvp.allergies}</em>
                  </Paragraph>
                )}
              </div>
            )
          })}
          {response.message && (
            <>
              <Heading level="3">Message</Heading>
              <Paragraph
                style={{
                  paddingLeft: "var(--s2)",
                  borderLeft: "5px solid #ccc",
                }}
              >
                {response.message.split("\n").map((line, i, lines) => (
                  <>
                    {line}
                    {lines.length - 1 !== i && <br />}
                  </>
                ))}
              </Paragraph>
            </>
          )}
        </div>
      ))}
    </>
  )
}
