import React from "react"
import Layout from "../../components/Layout"
import { ReportScreen } from "../../screens/rsvp/Report"
import {
  useRsvpResponses,
} from "../../hooks/useRsvpResponses"

export default function RSVPReportScreen() {
  const { data: responses, isLoading, error } = useRsvpResponses()

  return (
    <Layout title="RSVP Report">
      {error ? (
        error.message
      ) : isLoading ? (
        "Loading responses..."
      ) : (
        <ReportScreen responses={responses} />
      )}
    </Layout>
  )
}
