import { useEffect, useState } from "react"
import {
  RSVPCombinedResponse,
} from "../../functions/api/rsvp/types"


export const useRsvpResponses = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<RSVPCombinedResponse[]>([])
  const [error, setError] = useState<null | Error>(null)

  useEffect(() => {
    ;(async () => {
      const req = await fetch("/api/rsvp/report", {
        headers: {
          Authorization: `Bearer ${new URLSearchParams(location.search).get("token")}`,
        },
      })

      if (req.ok) {
        const responses = await req.json()
        setData(responses)
        setLoading(false)
      } else {
        setError(new Error("Failed to load report"))
        setLoading(false)
      }
    })()
  }, [])

  return { data, isLoading, error }
}
